import { Injectable } from "@angular/core";

export class OptixMenuItem {
    constructor(
        public name: string,
        public role: string,
        public route: string,
        public subMenu?: OptixMenuItem[],
        public isExternal: boolean = false,
        public externalUrlSettingName: string = ''
    ) { }
}

@Injectable({
    providedIn: 'root'
})
export class MenuBase {

    /**
     * Full list of the menu items that could appear.
     * Parent menu items cannot have a route, if it does, this will prevent checking and
     * adding of any child items
     */
    public fullMenu: OptixMenuItem[] = [
        new OptixMenuItem('Dashboard', '', '', [
            new OptixMenuItem('Tenant Dashboard', 'App.Tenant.AssetDashboard_Read', '/main/:tenant/dashboard'),
            new OptixMenuItem('Landing', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Landing_Read', '/main/:tenant/:asset/emissions/energy-efficiency/landing'),
            new OptixMenuItem('Home', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Home_Read', '/main/:tenant/:asset/emissions/energy-efficiency/home'),
            new OptixMenuItem('e.AI Operational Report', 'App.Tenant.Asset.Emissions.EnergyEfficiency.OperationalReport_Read', '/main/:tenant/:asset/emissions/energy-efficiency/operational-report'),
            new OptixMenuItem('Operating Status', 'App.Tenant.Asset.Emissions.EnergyEfficiency.OperatingStatus_Read', '/main/:tenant/:asset/emissions/energy-efficiency/operating-status'),
            new OptixMenuItem('History', 'App.Tenant.Asset.Emissions.EnergyEfficiency.History_Read', '/main/:tenant/:asset/emissions/energy-efficiency/history'),
            new OptixMenuItem('Summary', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Summary_Read', '/main/:tenant/:asset/emissions/energy-efficiency/summary')
            ]),
        new OptixMenuItem('Analysis', '', '', [
            new OptixMenuItem('Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Analysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/analysis'),
            new OptixMenuItem('Turbine Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.TurbineAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/turbine-analysis'),
            new OptixMenuItem('Furnace Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.FurnaceAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/furnace-analysis'),
            new OptixMenuItem('Steam Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.SteamAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/steam-analysis'),
            new OptixMenuItem('Boilers', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Boilers_Read', '/main/:tenant/:asset/emissions/energy-efficiency/boilers'),
            new OptixMenuItem('Heaters (WHRU)', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Heaters_Read', '/main/:tenant/:asset/emissions/energy-efficiency/heaters'),
            new OptixMenuItem('Waste Heat Recovery (WHR)', 'App.Tenant.Asset.Emissions.EnergyEfficiency.Whru_Read', '/main/:tenant/:asset/emissions/energy-efficiency/whr'),
            new OptixMenuItem('Power Generation', 'App.Tenant.Asset.Emissions.EnergyEfficiency.PowerGeneration_Read', '/main/:tenant/:asset/emissions/energy-efficiency/power-generation'),
            new OptixMenuItem('Import Electricity', 'App.Tenant.Asset.Emissions.EnergyEfficiency.ImportElectricity_Read', '/main/:tenant/:asset/emissions/energy-efficiency/import-electricity'),
            new OptixMenuItem('Hot Oil', 'App.Tenant.Asset.Emissions.EnergyEfficiency.HotOil_Read', '/main/:tenant/:asset/emissions/energy-efficiency/hot-oil'),
            new OptixMenuItem('Fired Heaters', 'App.Tenant.Asset.Emissions.EnergyEfficiency.FiredHeaters_Read', '/main/:tenant/:asset/emissions/energy-efficiency/fired-heaters'),
            new OptixMenuItem('Performance Baseline', 'App.Tenant.Asset.Emissions.EnergyEfficiency.DynamicBaseline_Read', '/main/:tenant/:asset/emissions/energy-efficiency/performance-baseline'),
            new OptixMenuItem('Thermal Oxidizers', 'App.Tenant.Asset.Emissions.EnergyEfficiency.ThermalOxidizers_Read', '/main/:tenant/:asset/emissions/energy-efficiency/thermal-oxidizers'),
            new OptixMenuItem('Crude Arrival', 'App.Tenant.Asset.Emissions.EnergyEfficiency.CrudeArrival_Read', '/main/:tenant/:asset/emissions/energy-efficiency/crude-arrival'),
            new OptixMenuItem('Statistical Analysis', 'App.Tenant.Asset.Emissions.EnergyEfficiency.StatisticalAnalysis_Read', '/main/:tenant/:asset/emissions/energy-efficiency/statistical-analysis'),
            new OptixMenuItem('Performance Profile', 'App.Tenant.Asset.Emissions.EnergyEfficiency.PerformanceProfile_Read', '/main/:tenant/:asset/emissions/energy-efficiency/performance-profile')
        ]),
        new OptixMenuItem('Compliance', '', '', [
            new OptixMenuItem('Energy Report', 'App.Tenant.Asset.Emissions.EnergyEfficiency.EnergyReport_Read', '/main/:tenant/:asset/emissions/energy-efficiency/energy-report'),
            new OptixMenuItem('Running Hours', 'App.Tenant.Asset.Emissions.EnergyEfficiency.RunningHours_Read', '/main/:tenant/:asset/emissions/energy-efficiency/running-hours'),
            new OptixMenuItem('System Status', 'App.Tenant.Asset.Emissions.EnergyEfficiency.SystemStatus_Read', '/main/:tenant/:asset/emissions/energy-efficiency/system-status'),
            new OptixMenuItem('Diesel Reconciliation', 'App.Tenant.Asset.Emissions.EnergyEfficiency.DieselReconciliation_Read', '/main/:tenant/:asset/emissions/energy-efficiency/diesel-reconciliation')
        ]),
        new OptixMenuItem('Flaring', '', '', [
            new OptixMenuItem('Summary', 'App.Tenant.Asset.Emissions.Flaring.Summary_Read', '/main/:tenant/:asset/emissions/flaring/summary'),
            new OptixMenuItem('Analysis', 'App.Tenant.Asset.Emissions.Flaring.Analysis_Read', '/main/:tenant/:asset/emissions/flaring/analysis'),
            new OptixMenuItem('Consent Tracking', 'App.Tenant.Asset.Emissions.Flaring.ConsentTracking_Read', '/main/:tenant/:asset/emissions/flaring/consent-tracking'),
            new OptixMenuItem('Reconciliation', 'App.Tenant.Asset.Emissions.Flaring.Reconciliation_Read', '/main/:tenant/:asset/emissions/flaring/reconciliation')
        ]),
        new OptixMenuItem('PEMS', '', '', [
            new OptixMenuItem('Home', 'App.Tenant.Asset.Emissions.Pems.Home_Read', '/main/:tenant/:asset/emissions/pems/home'),
            new OptixMenuItem('Mass Emissions', 'App.Tenant.Asset.Emissions.Pems.MassEmissions_Read', '/main/:tenant/:asset/emissions/pems/mass-emissions'),
            new OptixMenuItem('Analysis', 'App.Tenant.Asset.Emissions.Pems.Analysis_Read', '/main/:tenant/:asset/emissions/pems/analysis'),
            new OptixMenuItem('Operating Status', 'App.Tenant.Asset.Emissions.Pems.OperatingStatus_Read', '/main/:tenant/:asset/emissions/pems/operating-status')
        ]),
        new OptixMenuItem('Methane', '', '', [
            new OptixMenuItem('Home', 'App.Tenant.Asset.Emissions.Methane.Home_Read', '/main/:tenant/:asset/emissions/methane'),
        ]),
        new OptixMenuItem('Communications', '', '', [
            new OptixMenuItem('Dashboard', 'App.Tenant.Asset.Communications.Dashboard_Read', '/main/:tenant/:asset/communications/dashboard'),
            new OptixMenuItem('Investigations', 'App.Tenant.Asset.Communications.XInvestigations_Read', '/main/:tenant/:asset/communications/investigations'),
            new OptixMenuItem('Insights', 'App.Tenant.Asset.Communications.XInsights_Read', '/main/:tenant/:asset/communications/insights')
        ]),
        new OptixMenuItem('Trends', '', '', [
            new OptixMenuItem('X-PAS', 'App.Tenant.Asset.XPAS_Read', '', undefined, true, 'XpasHomeUrl'),
            new OptixMenuItem('Trends emissions.AI', 'App.Tenant.Asset.Trends_Read', '', undefined, true, 'XpasHomeUrl'),
            new OptixMenuItem('Trends X-PAS Lite', 'App.Tenant.Asset.XPASLite_Read', '', undefined, true, 'XpasLiteHomeUrl'),

        ]),
        new OptixMenuItem('Asset Injection', '', '', [
            new OptixMenuItem('Master Portal', 'App.Tenant.Asset.AssetInjection.MasterPortal_Read', '/main/:tenant/:asset/asset-injection/master-portal'),
            new OptixMenuItem('Injection Portal', 'App.Tenant.Asset.AssetInjection.InjectionPortal_Read', '/main/:tenant/:asset/asset-injection/injection-portal'),
            new OptixMenuItem('Client Portal', 'App.Tenant.Asset.AssetInjection.ClientPortal_Read', '/main/:tenant/:asset/asset-injection/client-portal')
        ]),
        new OptixMenuItem('Root Admin', '', '', [
            //new OptixMenuItem('Api Test', '', '/root/api-test', ''),
            new OptixMenuItem('Tenants', 'Root.Tenants_Read', '/root/tenants'),
            new OptixMenuItem('Users', 'Root.Users_Read', '/root/users'),
            new OptixMenuItem('Analytics', 'Root.Analytics_Read', '/root/analytics'),
            new OptixMenuItem('Modules', 'Root.Modules_Read', '/root/modules'),
            new OptixMenuItem('Roles', 'Root.Roles_Read', '/root/roles'),
            new OptixMenuItem('Applications', 'Root.Applications_Read', '/root/applications'),
            new OptixMenuItem('Notification Messages', 'Root.NotificationMessages_Read', '/root/notification-messages'),
            new OptixMenuItem('Federated Domains', 'Root.FederatedDomains_Read', '/root/federated-domains'),
            new OptixMenuItem('User Manuals', 'Root.UserManuals_Read', '/root/user-manuals'),
            new OptixMenuItem('OPTiX Settings', 'Root.Settings_Read', '/root/settings'),
            new OptixMenuItem('Chart Settings', 'Root.ChartSettings_Read', '/root/chart-settings'),
            new OptixMenuItem('Card Settings', 'Root.CardSettings_Read', '/root/card-settings')
        ]),
        new OptixMenuItem('Tenant Administration', '', '', [
            new OptixMenuItem('Assets', 'App.Tenant.Admin.Assets_Read', '/main/:tenant/admin/assets'),
            new OptixMenuItem('Users', 'App.Tenant.Admin.Users_Read', '/main/:tenant/admin/users'),
            new OptixMenuItem('Analytics', 'App.Tenant.Admin.Analytics_Read', '/main/:tenant/admin/analytics'),
            new OptixMenuItem('Tenant Settings', 'App.Tenant.Admin.Settings_Read', '/main/:tenant/admin/tenant-settings'),
            new OptixMenuItem('Chart Settings', 'App.Tenant.Admin.ChartSettings_Read', '/main/:tenant/admin/chart-settings'),
            new OptixMenuItem('Card Settings', 'App.Tenant.Admin.CardSettings_Read', '/main/:tenant/admin/card-settings')
        ]),
        new OptixMenuItem('Asset Administration', '', '', [
            new OptixMenuItem('Analytics', 'App.Tenant.Asset.Analytics_Read', '/main/:tenant/:asset/admin/analytics'),
            new OptixMenuItem('Inventory', 'App.Tenant.Asset.Systems_Read', '/main/:tenant/:asset/admin/inventory'),
            new OptixMenuItem('Notification Messages', 'App.Tenant.Asset.Admin.Notifications_Read', '/main/:tenant/:asset/admin/notification-messages'),
            new OptixMenuItem('Units Of Measure', 'App.Tenant.Asset.Admin.UnitsOfMeasure_Read', '/main/:tenant/:asset/admin/units-of-measure'),
            new OptixMenuItem('Database Scaling', 'App.Tenant.Asset.Admin.DatabaseScaling_Read', '/main/:tenant/:asset/admin/database-scaling')
        ]),
        new OptixMenuItem('Support', '', '', [
            new OptixMenuItem('File Repository', 'App.Tenant.Asset.FileRepository_Read', '/main/:tenant/:asset/file-repository'),
            new OptixMenuItem('User Manual', '',  '', undefined, true, 'UserManualUrl'),
            new OptixMenuItem('Support Documentation', 'App.Tenant.Asset.SupportDocumentation_Read', '/main/support/:tenant/:asset/support-documentation'),
            new OptixMenuItem('Contact Us', '', '/main/support/contact-us'),
        ])
    ]

    constructor() { }
}